
.rs-play {
    width: 45px;
    height: 45px;
    border-radius: 14px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
}

