@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400;500;600&display=swap');

body {
    font-family: 'Prompt', sans-serif;
}

body > #root > div {
    height: 100vh;
}

*{-ms-overflow-style: none;}
::-webkit-scrollbar {display:none;}

div.header {
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
}

div.title {
    font-family: 'Prompt', sans-serif;
    font-weight: 500;
    font-size: 17px;
}

div.regular {
    font-family: 'Prompt', sans-serif;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
}

div.counterDetail {
    font-family: 'Prompt', sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7c352a;
}

div.count {
    font-family: 'Prompt', sans-serif;
    font-weight: 500;
    font-size: 27px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

div.headerContainer {
    padding-top: 42px;
    padding-bottom: 17px;
}

a, a:visited, a:hover, a:active {
    text-decoration: none;
    color: inherit;
}

.material-icons-round.blueForward { color: #005b8e; }

.default-button {
    background-color: white;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    width: 100%;
    padding: 8px 20px;
    -webkit-tap-highlight-color: transparent;
}

.default-button:active {
    background-color: rgba(0, 0, 0, 0.11);
}

.header-button {
    width: 100%;
    background-color: white;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: left;
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #202027;
    padding: 16px 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-tap-highlight-color: transparent;
}

.header-button:active {
    background-color: rgba(0, 0, 0, 0.11);
}

.rounded-button {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 2px 20px 0 rgba(0, 64, 128, 0.1);
    background-color: #ffffff;
    display: flex;
    padding: 16px;
    margin-bottom: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: left;
    -webkit-tap-highlight-color: transparent;
}

.rounded-button:active {
    background-color: rgba(0, 0, 0, 0.11);
}

.vocab-button {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 rgba(0, 64, 128, 0.15);
    background-color: #ffffff;
    display: flex;
    padding: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 20px;
    text-align: left;
    align-items: center;
    align-content: center;
    line-height: 0;
    -webkit-tap-highlight-color: transparent;
}

.vocab-button:active {
    background-color: rgba(0, 0, 0, 0.11);
}

.register-button {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    background-color: #005b8e;
    width: 100%;
    height: 50px;
    color: #ffffff;
    font-size: 16px;
    border-radius: 22px;
    box-shadow: 0 2px 20px 0 rgba(37, 116, 255, 0.15);
    border: solid 1px #005b8e;
    outline: none;
    cursor: pointer;
    line-height: 0;
    -webkit-tap-highlight-color: transparent;
}

.register-button:active {
    background-color: #00477a;
}

.purchase-state {
    background-color: #b00026;
    border: solid 1px #b00026;
}

.purchase-state:active {
    background-color: #7d001d;
}

.item-vocab {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    flex-grow: 1;
    background-color: #ffffff;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    padding-left: 0px;
}

.item-vocab:active {
    background-color: rgba(0, 0, 0, 0.11);
}

.bottom-button {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    background-color: #005b8e;
    width: 100%;
    height: 50px;
    color: #ffffff;
    font-size: 16px;
    border-radius: 22px;
    box-shadow: 0 2px 20px 0 rgba(37, 116, 255, 0.15);
    border: solid 1px #005b8e;
    outline: none;
    cursor: pointer;
    line-height: 0;
    -webkit-tap-highlight-color: transparent;
}

.bottom-button:active {
    background-color: #00477a;
}

.button-translate {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    flex-grow: 0;
    background-color: #ffffff;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    padding: 8px;
}

.button-translate:active {
    background-color: rgba(0, 0, 0, 0.11);
}

.aloha-text-field {
    position: relative;
    padding-right: 8px;
}

.aloha-text-field-input {
    font-family: "Prompt", sans-serif;
    font-weight: 300;
    font-size: 16px;
    width: 100%;
    border-width: 0 0 1px;
    line-height: 1;
    outline: none;
    padding-bottom: 8px;
    padding-top: 8px;
    border-radius: 0;
}

.aloha-text-field-button {
    font-family: "Prompt", sans-serif;
    font-weight: 400;
    font-size: 16px;
    position: absolute;
    right: 3px; 
    top: 3px;
    bottom: 3px;
    border: 0;
    color: #1fbe7a;
    background-color: #ffffff;
    outline: none;
    margin: 0;
    z-index: 2;
    cursor: pointer;
    border-radius: 4px;
}

.aloha-text-field-button:active {
    background-color: rgba(0, 0, 0, 0.11);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #888992;
    opacity: 1; /* Firefox */
  }

input {
    -webkit-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0px;
}