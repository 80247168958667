.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorBottom.MuiPaper-elevation16 {
    border-radius: 25px 25px 0 0;
    max-height: 80%;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.scrollTypeGroup {
    overflow:   scroll;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}